
p {
    margin: 0;
    padding: 0;
}

.box_header {
    width: 100%;
    height: 112px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    display: flex;
    align-items: center;
    padding-left: 24px;
    box-sizing: border-box;
    padding: 24px;
    box-sizing: border-box;

    .header {

        width: 64px;
        height: 64px;
        border-radius: 100%;
        flex-shrink: 0;

        img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
        }

    }

    .info {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 22px;
        color: #000000;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .name {
            max-width: 217px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
        }

        .number {
            color: #7A7E88;
            font-size: 20px;

        }



    }

    .switch_account {
        width: 100px;
        height: 100%;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #888888;
        text-align: left;
        font-style: normal;
        text-transform: none;
        flex-shrink: 0;
        margin-left: 20px;
        margin-top: 4px;

        i {
            margin-left: 5px;
        }



    }

    .account_info {
        display: flex;
        height: 100%;

        .name_select {
            display: flex;
            align-items: center;
        }


        .item {
            width: 160px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #4D4F56;

            span {
                display: inline-block;
            }

            .number {
                font-family: Douyin Sans, Douyin Sans;
                font-weight: bold;
                font-size: 24px;
                color: #171A1E;
                text-align: left;
                font-style: normal;
                text-transform: none;

            }
        }
    }

}

.no_data {
    width: 100%;
    height: 112px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    display: flex;
    align-items: center;
    padding-left: 24px;
    box-sizing: border-box;
    padding: 24px;
    box-sizing: border-box;
    justify-content: space-between;

    .left {
        height: 100%;
        display: flex;
        align-items: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 20px;
        color: #7A7E88;

        .img {
            width: 64px;
            height: 64px;
            object-fit: cover;

        }
    }

    .el-button {
        width: 88px;
        height: 32px;
        background: #594FEE;
        border-radius: 4px 4px 4px 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
    }
}

.re_bind_douyin {
    width: 60px;
    height: 22px;
    background: #F2F2F4;
    border-radius: 4px 4px 4px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #594FEE;
    border: none;
    margin-left: 10px;

}

.account_list {
    width: 100%;
    max-height: 280px;
    overflow-y: auto;
    overflow-x: hidden;
    // scrollbar-width: none;
    // -ms-overflow-style: none;
    // scrollbar-width: none;
    -ms-overflow-style: thin;
    // scrollbar-color: #e1dfdf #f1f1f1;
    /* 滚动条滑块和轨道颜色 */

    .account_item {

        width: 260px;
        height: 69px;
        padding: 12px 0px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        cursor: pointer;


        .avatar {
            width: 45px;
            height: 45px;
            border-radius: 100%;

            img {
                width: 100%;
                height: 100%;
                border-radius: 100%;
            }
        }

        .info {
            width: 178px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-left: 12px;
            box-sizing: border-box;

            .name {
                width: 178px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 18px;
                color: #000000;

            }

            .number {
                color: #7A7E88;
                font-weight: 400;
                font-size: 16px;
                margin-top: 4px;

            }
        }

        .icon_box {
            width: 20px;
            height: 20px;
            margin-left: 20px;

        }
    }

}

.el-popover {
    padding-right: 0px !important;
}

/* 设置滚动条整体 */
::-webkit-scrollbar {
    width: 4px;
    /* 滚动条宽度 */
    height: 6px;
    /* 水平滚动条高度 */
}

/* 设置滚动条轨道 */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* 轨道背景色 */
}

/* 设置滚动条滑块 */
::-webkit-scrollbar-thumb {
    background: #e1dfdf;
    /* 滑块背景色 */
    border-radius: 4px;
    /* 滑块圆角 */
}

/* 设置滚动条滑块悬停时的样式 */
::-webkit-scrollbar-thumb:hover {
    background: #c9c9c9;
    /* 滑块悬停背景色 */
}
