
P {
    margin: 0;
    padding: 0;
}

.data_center_box {
    width: 100%;
    height: 100%;
    padding: 16px;
    box-sizing: border-box;

    .title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;

        .title_left {
            color: #BBBBBD;

            .data_title {
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                font-size: 18px;
                color: #252632;
            }

            .title_icon {
                margin: 0 5px;
            }

            .time {
                font-weight: 400;
                font-size: 12px;
                color: #888888;
            }
        }

        .title_right {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #888888;
            cursor: pointer;
        }
    }

    .show_data {
        width: 100%;
        display: flex;
        margin-top: 10px;

        .data_left {
            width: 210px;
            box-sizing: border-box;
            border-right: 1px solid #F2F2F4;
            padding-right: 16px;
            box-sizing: border-box;
            flex-shrink: 0;

            .new_work {
                padding: 10px 0;
                margin-bottom: 2px;

            }

            .video {
                margin-top: 10px;
                width: 100%;
                height: 272px;
                //  background-color: skyblue;
                border-radius: 8px;
                font-size: 0;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                    object-fit: cover;
                }

                .desc {
                    width: 92%;
                    position: absolute;
                    left: 10px;
                    top: 20px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    color: #fff;
                    font-size: 12px;
                    font-weight: 500;
                    z-index: 100;

                }

                .desc_type {
                    top: 10px;
                    display: flex;
                    justify-content: flex-end;
                }

                .video_info {
                    width: 100%;
                    position: absolute;
                    left: 0;
                    //  background: linear-gradient(180deg, rgba(30, 30, 30, 0), rgba(30, 30, 30, .5));
                    border-bottom-left-radius: 8px;
                    border-bottom-right-radius: 8px;
                    bottom: 0;
                    box-sizing: border-box;
                    cursor: pointer;
                    height: 100%;


                    overflow: hidden;
                    padding-bottom: 16px;
                    transition: all .25s ease-in-out;

                    .no_content {
                        width: 100%;
                        height: 81%;
                        background-color: transparent;
                        transition: all .25s ease-in-out;
                        border-top-left-radius: 8px;
                        border-top-right-radius: 8px;

                    }

                    .content {
                        width: 100%;
                        padding: 5px 20px;
                        box-sizing: border-box;
                        backdrop-filter: blur(10px);
                        // background:  linear-gradient(180deg, rgba(30, 30, 30, 0), rgba(30, 30, 30, .5));

                        p {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            font-family: PingFang SC, PingFang SC;
                            font-weight: 500;
                            font-size: 12px;
                            color: #FFFFFF;
                            padding: 5px 0;

                            span {
                                display: inline-block;
                            }
                        }
                    }




                    &:hover {
                        backdrop-filter: blur(10px);
                        // background:  linear-gradient(180deg, rgba(30, 30, 30, 0), rgba(30, 30, 30, .5));
                        border-top-left-radius: 8px;
                        border-top-right-radius: 8px;

                        .no_content {
                            height: 46%;

                        }

                        .content {
                            background-color: transparent;
                        }



                    }


                }

            }

            .no_video {
                width: 100%;
                height: 272px;
                background: #F9F9FA;
                border-radius: 8px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                img {
                    width: 120px;
                    height: 120px;
                }

                .text {
                    width: 130px;
                    white-space: wrap;
                    text-align: center;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 500;
                    font-size: 14px;
                    color: rgba(28, 31, 35, 0.55);

                }
            }
        }

        .data_right {
            width: 84%;
            box-sizing: border-box;
            margin-left: 1%;

            .data_header {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid #F2F2F4;
                align-items: center;

                .header_left {
                    display: flex;

                    .type_item {
                        padding: 10px 0;
                        margin-right: 20px;
                        cursor: pointer;
                    }

                    .active_type_item {
                        border-bottom: 2px solid #685EF5;
                    }


                }

                .header_right {
                    background: #F2F2F4;
                    //  border-radius: 4px 4px 4px 4px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    font-size: 12px;
                    color: #888888;
                    padding-left: 10px;
                    padding-right: 2px;
                    border-radius: 6px;
                    border: 1px solid transparent;

                    &:hover {
                        // border: 1px solid #FF0000;
                    }

                    .el-select {
                        width: 90px;
                        //  border-top-right-radius: 4px !important;
                        //  border-bottom-right-radius: 4px !important;

                        ::v-deep .el-input__inner {
                            height: 24px;
                            line-height: 24px;
                            border-color: transparent;
                            background: #F2F2F4;
                            border-radius: 0px;
                            padding: 0 10px;
                            //  border-top-right-radius: 4px !important;
                            //  border-bottom-right-radius: 4px !important;

                        }

                        ::v-deep .el-input__icon {
                            line-height: 24px;
                        }




                    }
                }
            }

            .data_box {

                width: 100%;

                .no_echarts {
                    width: 100%;
                    height: 262px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background: #F9F9FA;
                    border-radius: 4px 4px 4px 4px;
                    margin-top: 10px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 500;
                    font-size: 14px;
                    color: rgba(28, 31, 35, 0.55);

                }

                .echarts {
                    width: 100%;

                    .data_echarts {
                        width: 100%;
                        height: 160px;
                        //  background-color: skyblue;
                    }

                    .select_box {
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;

                        .select_item {
                            width: 33.33%;
                            padding: 10px 10px;
                            box-sizing: border-box;
                            cursor: pointer;

                            &:hover {
                                background-color: #f9f9fa;
                            }

                            .selectType {
                                font-family: PingFang SC, PingFang SC;
                                font-weight: 500;
                                font-size: 12px;
                                color: #252632;

                            }

                            .activeSelectType {
                                position: relative;
                                padding-left: 10px;
                                transition: padding-left 0.3s ease;
                                /* 文字位置的过渡动画 */
                                color: #685EF5;

                                &::before {
                                    content: '';
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    width: 4px;
                                    /* 竖条的宽度 */
                                    height: 100%;
                                    /* 竖条的高度，与内容区域高度一致 */
                                    background-color: #685EF5;
                                    /* 竖条的颜色 */
                                    border-radius: 4px;
                                }
                            }

                            .number {
                                display: flex;
                                align-items: center;

                                span {
                                    display: inline-block;
                                }

                                .num {
                                    font-family: Douyin Sans, Douyin Sans;
                                    font-weight: bold;
                                    font-size: 20px;
                                    color: #000000;
                                }

                                .date {
                                    font-family: PingFang SC, PingFang SC;
                                    font-weight: 400;
                                    font-size: 12px;
                                    color: #999999;
                                    margin-left: 5px;
                                }

                                .changenum {
                                    font-family: PingFang SC, PingFang SC;
                                    font-weight: 400;
                                    font-size: 12px;
                                    margin-left: 5px;
                                }
                            }

                        }
                    }


                }

                .recentWorks_item {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 58px;
                    padding: 5px 10px;
                    box-sizing: border-box;
                    cursor: pointer;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    font-size: 12px;
                    color: #242632;

                    &:hover {
                        background-color: #F9F9FA;

                        .recent_data {
                            .Works_data {
                                .viewAnalysis {
                                    width: 20%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-end;
                                }
                            }
                        }
                    }


                    .image {
                        width: 32px;
                        height: 42px;
                        border-radius: 4px 4px 4px 4px;

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 4px 4px 4px 4px;
                            object-fit: cover;
                        }
                    }

                    .recent_data {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        margin-left: 10px;

                        .description {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 5px;

                            .status_name {
                                flex: 1;
                                width: 0;
                                flex-shrink: 0;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 1;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                line-height: 1.5;
                                max-height: 3em;
                                padding-right: 10px;
                                box-sizing: border-box;

                            }

                            .time {
                                width: 165px;
                                display: block;
                                font-family: PingFang SC, PingFang SC;
                                font-weight: 400;
                                font-size: 12px;
                                color: #888888;


                                span:nth-of-type(1) {
                                    color: #61C26E;
                                    margin-right: 10px;
                                }

                            }
                        }

                        .Works_data {
                            width: 100%;
                            display: flex;
                            align-items: center;

                            .work_item {
                                width: 20%;

                                span:nth-of-type(1) {
                                    color: #888888;
                                }

                                span:nth-of-type(2) {
                                    font-family: Douyin Sans, Douyin Sans;
                                    font-weight: bold;
                                    font-size: 14px;
                                    color: #242632;
                                    margin-left: 10px;
                                }
                            }

                            .viewAnalysis {
                                width: 20%;
                                font-family: PingFang SC, PingFang SC;
                                font-weight: 400;
                                font-size: 12px;
                                color: #685EF5;
                                cursor: pointer;
                                display: none;
                                //  display: flex;
                                //  align-items: center;
                                //  justify-content: flex-end;
                            }

                        }
                    }
                }

                .no_video {
                    width: 100%;
                    height: 262px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background: #F9F9FA;
                    margin-top: 10px;
                    border-radius: 4px 4px 4px 4px;

                }



            }

        }


    }

}
