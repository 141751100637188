
p {
    margin: 0;
    padding: 0;
}

.work-bench {
    // width: 100%;
    // height: 800px;
    // background-color: pink;
    overflow-x: auto;

    .header {
        width: 100%;
    }

    .newCreate_box {
        width: 100%;
        height: 153px;
        margin-top: 20px;
        display: flex;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #252632;
        line-height: 21px;
        text-align: left;
        font-style: normal;
        text-transform: none;



        .newCreate {
            width: 75%;
            // min-width: 800px;
            height: 100%;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            padding: 16px 16px 0px;
            box-sizing: border-box;

            p {
                font-size: 18px;
            }

            .content_box {
                width: 100%;
                display: flex;
                padding-top: 15px;
                box-sizing: border-box;


                .item_box {
                    width: 32.63%;
                    background-size: 100%;
                    background-repeat: no-repeat;
                    border-radius: 4px;
                    position: relative;
                    padding: 15px;
                    box-sizing: border-box;
                    display: flex;
                    cursor: pointer;
                    align-items: center;

                    .icon {
                        width: 64px;
                        height: 64px;
                        background-color: pink;
                        position: absolute;
                        top: 0px;
                        right: 0px;


                    }

                    .disable_btn {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 100;
                        opacity: 0.5;

                    }

                    &:nth-of-type(1) {
                        background: #fff2ea;

                        .icon {
                            background: url('../../../assets/img/work/icon1.png') no-repeat;
                        }

                        .disable_btn {
                            background: #fff2ea;

                        }

                    }

                    &:nth-of-type(2) {
                        margin-left: 1%;
                        margin-right: 1%;
                        background: #ecf6ff;

                        .icon {
                            background: url('../../../assets/img/work/icon2.png') no-repeat;
                        }

                        .disable_btn {
                            background: #ecf6ff;

                        }
                    }

                    &:nth-of-type(3) {
                        background: #f3f1ff;

                        .icon {
                            background: url('../../../assets/img/work/icon3.png') no-repeat;
                        }

                        .disable_btn {
                            background: #f3f1ff;

                        }
                    }

                    // &:nth-of-type(1):hover {
                    //     background-color: #ffe4e6;

                    //     .text_box {
                    //         color: #ff2c55;
                    //     }

                    // }

                    // &:nth-of-type(2):hover {
                    //     background: #d3eaff;


                    //     .text_box {
                    //         color: #1d92ff;
                    //     }

                    // }

                    // &:nth-of-type(3):hover {
                    //     background: #e3dfff;

                    //     .text_box {
                    //         color: #735cff;

                    //     }
                    // }

                    .icon_image {
                        width: 32px;
                        height: 32px;
                        font-size: 0;

                        img {
                            width: 100%;
                            height: 100%;
                            display: inline-block;
                        }
                    }

                    .text_box {
                        width: 85%;
                        margin-left: 10px;
                        font-family: PingFang SC, PingFang SC;
                        font-weight: 500;
                        font-size: 14px;
                        color: #252632;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        .tip {
                            width: 98%;
                            font-family: PingFang SC, PingFang SC;
                            font-weight: 400;
                            font-size: 12px;
                            color: #888888;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: inline-block;

                        }

                    }
                }
            }
        }

        .quick_navigation {
            // min-width: 340px;
            width: 23%;
            height: 100%;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            margin-left: 2%;
            padding: 16px 16px 0px;
            box-sizing: border-box;

            p {
                font-size: 18px;
            }

            .content_box {
                width: 100%;
                display: flex;
                padding-top: 15px;
                box-sizing: border-box;

                .item_box {
                    width: 23%;

                    // background-color: skyblue;
                    margin-left: 2%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    cursor: pointer;
                    justify-content: space-between;

                    img {
                        width: 70%;
                        max-width: 68px;
                    

                    }

                    span {
                        width: 100%;
                        text-align: center;
                        font-family: PingFang SC, PingFang SC;
                        font-weight: 500;
                        height: 20px;
                        font-size: 14px;
                        color: #333333;
                        margin-top: 2px;
                        overflow: hidden;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: inline-block;
                    }
                }

            }

        }

    }

    .data_center {
        width: 100%;
        height: 384px;
        margin-top: 20px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
    }

    .work-task {
        width: 100%;
        display: flex;

        .task {
            height: 434px;
            background: #ffffff;
            border-radius: 10px;
            margin: 24px 0;
            padding: 10px 20px 0;
            display: flex;
            width: 70%;
            position: relative;

            .view-more {
                position: absolute;
                text-align: center;
                font-size: 18px;
                color: #5E53F0;
                margin: 22px 0 18px 0;
                cursor: pointer;
                right: 20px;
                top: 0;
                z-index: 1;
            }
        }

        .task-right {
            width: 30%;
            height: 444px;
            background: #ffffff;
            border-radius: 10px;
            margin: 24px 0 24px 20px;
            display: flex;
            flex-direction: column;

            .show-right {
                display: flex;
                justify-content: space-between;
                padding: 20px;

                .show-name {
                    font-size: 18px;
                    color: #16113F;
                }

                .show-switch {
                    color: #16113F;
                    display: flex;
                    justify-content: space-between;
                    cursor: pointer;

                    .switch-icon {
                        transform: rotate(90deg);
                        margin-right: 6px;
                    }

                    .switch-text {
                        font-size: 14px;
                        color: #16113F;
                    }
                }
            }

            .task-right-box {
                padding: 0 15px;
                position: relative;
                flex: 1;

                .task-title {
                    max-width: 260px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .icon-background {
                    img {
                        width: 27px;
                        height: 27px;
                    }
                }

                .rank-title {
                    position: absolute;
                    left: 20%;
                    top: 25%;
                }
            }
        }

        .task-list {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            height: 100%;
            min-height: 360px;
            justify-content: flex-start;
            overflow: hidden;

            .list-item {
                width: calc(33% - 10px);
                height: 100%;
                margin-right: 16px;

                &:nth-of-type(3) {
                    margin-right: 0;
                }

                .container {
                    width: 100%;
                    border: 1px solid #E3E4EC;
                    transition: all .25s ease-out;
                    border-radius: 4px;
                    cursor: pointer;

                    .item-cover {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 200px;

                        img {
                            max-width: 100%;
                            max-height: 100%;
                            border-radius: 4px;
                            /*border-radius:8px 8px 0 0;*/
                        }

                        .sign {
                            padding: 5px;
                            position: absolute;
                            border-radius: 0;
                            top: 9px;
                            left: 6px;
                            display: inline-block;
                            background: #FFD107;
                            border-radius: 2px 2px 2px 2px;
                            font-family: PingFang SC, PingFang SC;
                            font-weight: 500;
                            font-size: 12px;
                            color: #161823;

                        }
                    }

                    .item-content {

                        padding: 10px 0 0 16px;

                        .item-title {
                            display: block;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            font-family: PingFang SC, PingFang SC;
                            font-weight: 500;
                            font-size: 16px;
                            color: #252632;
                            margin-bottom: 10px;
                        }

                        .task_money {
                            font-family: PingFang SC, PingFang SC;
                            font-weight: 400;
                            font-size: 12px;
                            color: #FF6900;

                            span:nth-of-type(2) {
                                font-size: 20px;
                                font-weight: 600;

                            }

                        }

                        .join_num {
                            font-family: PingFang SC, PingFang SC;
                            font-weight: 400;
                            font-size: 12px;
                            color: #666666;
                            padding: 10px 0;
                        }



                    }

                    .item-status {

                        width: 100%;
                        height: 56px;
                        border-top: 1px solid #F2F2F4;
                        font-family: PingFang SC, PingFang SC;
                        font-weight: 400;
                        font-size: 12px;
                        color: #666666;
                        display: flex;
                        padding: 0 16px;
                        box-sizing: border-box;
                        align-items: center;
                        justify-content: space-between;

                        .task_btn {
                            width: 80px;
                            height: 32px;
                            // background: #EBEAFF;
                            border-radius: 4px 4px 4px 4px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        // .view-task {
                        //     color: #2338E6;
                        // }

                        // .finish-task {
                        //     color: #20C997;
                        // }

                        // .time-out-task {
                        //     color: #666666;
                        // }
                    }
                }
            }
        }

        .no-task-list {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
    }

    .all_data_center {
        width: 100%;
        display: flex;

        .data_left {

            height: 649px;
            background: #ffffff;
            border-radius: 10px;
            margin-bottom: 24px;
            padding: 10px 0;
            // display: flex;
            // width: 69%;
            flex: 1;
            width: 0;
            box-sizing: border-box;

            .title {
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                font-size: 18px;
                color: #252632;
                padding-top: 10px;
                padding-left: 20px;
                display: inline-block;
            }

            .data_content {
                width: 100%;
                display: flex;
                margin-top: 20px;
                height: 500px;

                .rank {
                    width: 36%;
                    // overflow-x: auto;

                    &:nth-of-type(1) {
                        width: 36%;
                        border-right: 1px solid #F2F2F4;
                        padding: 0 20px;
                        box-sizing: border-box;
                    }

                    &:nth-of-type(2) {
                        width: 32%;
                        border-right: 1px solid #F2F2F4;
                        padding: 0 20px;

                        box-sizing: border-box;
                    }

                    &:nth-of-type(3) {
                        width: 32%;
                        padding: 0 20px;
                        box-sizing: border-box;

                    }

                    .item {
                        width: 100%;

                        .header {
                            width: 100%;
                            border-bottom: 1px solid #F2F2F4;

                            .item_title {
                                font-family: PingFang SC, PingFang SC;
                                font-weight: 500;
                                font-size: 14px;
                                color: #242632;
                                padding-bottom: 10px;
                                border-bottom: 2px solid #594FEE;
                                display: inline-block;

                            }



                        }

                        .has_list {
                            .all_data {

                                width: 100%;
                                height: 70px;
                                margin: 10px 0;
                                display: flex;
                                font-size: 0;
                                cursor: pointer;

                                .image {
                                    width: 52px;
                                    height: 70px;
                                    border-radius: 4px;
                                    position: relative;
                                    font-size: 0;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        border-radius: 4px;
                                        object-fit: cover;
                                    }

                                    .icon_circle {
                                        width: 16px;
                                        height: 16px;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        background-color: #594FEE;
                                        color: #fff;
                                        font-weight: 500;
                                        font-size: 10px;
                                        border-top-left-radius: 4px;
                                        border-bottom-right-radius: 8px;
                                        text-align: center;
                                        line-height: 16px;
                                        border: 1px solid #fff;

                                    }

                                }

                                .video_info_box {
                                    width: 0;
                                    flex: 1;
                                    font-family: PingFang SC, PingFang SC;
                                    font-weight: 400;
                                    font-size: 14px;
                                    color: #252632;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: space-between;
                                    margin-left: 10px;
                                    padding: 6px 0;
                                    box-sizing: border-box;

                                    .video_title {
                                        width: 100%;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        display: inline-block;

                                    }

                                    .video_info {
                                        width: 100%;
                                        font-family: PingFang SC, PingFang SC;
                                        font-weight: 500;
                                        font-size: 12px;
                                        color: #252632;
                                        display: flex;

                                        .infor_item {
                                            width: 33.33%;
                                            display: inline-block;

                                            &:nth-of-type(1) {
                                                // width: 35%;
                                            }

                                            &:nth-of-type(2) {
                                                // width: 33%;
                                                margin: 0 10px;
                                            }

                                            &:nth-of-type(3) {
                                                // width: 32%;
                                            }

                                            .number {
                                                font-weight: bold;
                                            }

                                        }
                                    }
                                }


                            }

                            .bottom {
                                width: 100%;
                                height: 50px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-weight: 400;
                                font-size: 12px;
                                color: #888888;
                                cursor: pointer;
                            }
                        }

                        .no_list {
                            width: 100%;
                            display: flex;
                            margin-top: 20px;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            font-weight: 500;
                            font-size: 14px;
                            color: rgba(28, 31, 35, 0.55);
                        }


                    }
                }
            }
        }

        .data_right {

            width: 29.5%;
            height: 649px;
            background: #ffffff;
            border-radius: 10px;
            margin: 0px 0 24px 20px;
            display: flex;
            flex-direction: column;

            .fans_header {
                width: 100%;
                padding: 20px;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                font-weight: 400;
                font-size: 18px;
                color: #252632;
                align-items: center;

                .view_more {
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    font-size: 12px;
                    color: #888888;
                    cursor: pointer;
                }
            }

            .fans_list {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 0px 20px;
                box-sizing: border-box;
                margin-bottom: 10px;

                .image_box {
                    width: 60px;
                    height: 60px;
                    font-size: 0;
                    border-radius: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 100%;
                        object-fit: cover;
                    }
                }

                .fans_info {
                    width: 0;
                    flex: 1;
                    margin-left: 16px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .fans_name {
                        font-family: PingFang SC, PingFang SC;
                        font-weight: 400;
                        font-size: 14px;
                        color: #252632;
                        margin-bottom: 5px;
                    }

                    .fans_number {
                        display: flex;
                        font-family: PingFang SC, PingFang SC;
                        font-weight: 400;
                        font-size: 12px;
                        color: rgba(37, 38, 50, 0.6);
                        justify-content: space-between;

                        .fans {
                            width: 100px;
                            display: flex;
                            flex-direction: column;

                            span {
                                display: inline-block;
                            }


                        }
                    }
                }
            }

            .no_list {
                width: 100%;
                // height: 100%;
                display: flex;
                margin-top: 20px;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-weight: 500;
                font-size: 14px;
                color: rgba(28, 31, 35, 0.55);
            }

        }

    }

}
